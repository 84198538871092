import { render, staticRenderFns } from "./PatientVideo.vue?vue&type=template&id=f14f1738&scoped=true&"
import script from "./PatientVideo.vue?vue&type=script&lang=js&"
export * from "./PatientVideo.vue?vue&type=script&lang=js&"
import style0 from "./PatientVideo.scss?vue&type=style&index=0&id=f14f1738&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f14f1738",
  null
  
)

export default component.exports