import { render, staticRenderFns } from "./PatientLinks.vue?vue&type=template&id=00d07b77&scoped=true&"
import script from "./PatientLinks.vue?vue&type=script&lang=js&"
export * from "./PatientLinks.vue?vue&type=script&lang=js&"
import style0 from "./PatientLinks.scss?vue&type=style&index=0&id=00d07b77&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00d07b77",
  null
  
)

export default component.exports